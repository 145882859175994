import React from 'react';

import BlockActionSliderImage from './BlockActionSliderImage';

const BlockActionSlider = ({ isHomepage, title, type }) => {
  var position = 0;
  if (type === 'loyalty') {
    position = 4;
  } else if (type === 2) {
    position = 5;
  } else if (type === 13 || type === 4) {
    position = 6;
  } else if (type === 5) {
    position = 7;
  }

  var actionSliderClass = '';
  if (isHomepage == 'true') {
    actionSliderClass = 'homepage-content-body-special-block';
  } else {
    actionSliderClass = 'content-body-special-block';
  }

  return (
    <>
      <div className="special-action-block loyalty-block-content">
        <div className={actionSliderClass}>
          <BlockActionSliderImage isHomepage={isHomepage} title={title} position={position} type={type} />
        </div>
      </div>
    </>
  )
};

export default BlockActionSlider;